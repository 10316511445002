import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer>
      <div className={styles.top}>
        <div className={styles.logo}>
          <a href='#home'>Carlos Ramirez</a>
        </div>
        <div className={styles.socialLinks}>
          <a
            href='https://www.linkedin.com/in/cjramirez11/'
            target='_blank'
            rel='noreferrer'
          >
            <i class='fa-brands fa-linkedin fa-xl'></i>
          </a>
          <a
            href='https://github.com/cjislegit'
            target='_blank'
            rel='noreferrer'
          >
            <i class='fa-brands fa-square-github fa-xl'></i>
          </a>
        </div>
      </div>
      <div className={styles.middle}></div>
      <div className={styles.bottom}>Made with coffee by CJR</div>
    </footer>
  );
};

export default Footer;
