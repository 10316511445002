import React from 'react';
import { useState } from 'react';

import styles from './navBar.module.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    setIsOpen((current) => !current);
  };

  return (
    <React.Fragment>
      <nav className={styles.navbarContainer}>
        <div className={`${styles.logo} ${isOpen ? styles.open : null}`}>
          <a href='#home'>Carlos Ramirez</a>
        </div>
        <div className={styles.menu}>
          <ul className={isOpen ? styles.open : null}>
            <li>
              <a href='#home'>Home</a>
            </li>
            <li>
              <a href='#skills'>Skills</a>
            </li>
            <li>
              <a href='#about'>About</a>
            </li>
            <li>
              <a href='#portfolio'>Portfolio</a>
            </li>
          </ul>
        </div>
        <div className={styles.contact}>
          <a href='mailto:carlosjramirez@gmail.com'>Contact Me</a>
        </div>
        <button className={styles.menuButton} onClick={handleClick}>
          <i class='bx bx-menu'></i>
        </button>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
