import styles from './Skills.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHtml5,
  faCss3Alt,
  faJs,
  faReact,
  faGitAlt,
  faPhp,
} from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const skills = [
  {
    skill: 'HTML',
    icon: faHtml5,
    text: 'Expert in crafting semantic, user-focused, and SEO-friendly web webpages using HTML.',
  },
  {
    skill: 'CSS',
    icon: faCss3Alt,
    text: 'Skilled in responsive, cross-browser design techniques using CSS for visually appealing layouts.',
  },
  {
    skill: 'JavaScript',
    icon: faJs,
    text: 'Advanced expertise in JavaScript for building interactive, high-performance web applications. ',
  },
  {
    skill: 'React',
    icon: faReact,
    text: 'Experienced in building efficient, scalable UI components with React for modern web applications.',
  },
  {
    skill: 'Git',
    icon: faGitAlt,
    text: 'Proficient in Git for streamlined code management, ensuring efficient team collaboration. ​​',
  },
  {
    skill: 'PHP',
    icon: faPhp,
    text: 'Expert in PHP for robust backend development, enhancing website functionality and speed. ',
  },
  {
    skill: 'SQL',
    icon: faDatabase,
    text: 'Skilled in SQL for complex data queries and database optimization for enhanced performance and efficiency.',
  },
];

const Skills = () => {
  return (
    <div id='skills' className={styles.skillsContainer}>
      <h2>Skills</h2>
      <div className={styles.skillsIcons}>
        {skills.map((icon) => {
          return (
            <div className={styles.skillIcon}>
              <FontAwesomeIcon icon={icon.icon} className={styles.icon} />
              <span>{icon.skill}</span>
              <span className={styles.bodyText}>{icon.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Skills;
