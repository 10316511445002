import styles from './home.module.css';
import laptop from '../../assets/612 1.svg';

const home = () => {
  return (
    <div id='home' className={styles.homeContainer}>
      <img src={laptop} alt='Laptop icon' />
      <div className={styles.text}>
        <div className={styles.heading}>Hello, I am Carlos</div>
        <div className={styles.name}>
          I'm a <h1>web developer</h1> that builds pixel perfect websites and
          applications
        </div>

        <a href='mailto:carlosjramirez@gmail.com'>Contact Me</a>
      </div>
    </div>
  );
};

export default home;
