import NavBar from './components/navBar/navBar';
import About from './components/pages/about/about';
import Footer from './components/pages/footer/footer';
import Home from './components/pages/home';
import Skills from './components/pages/skills/Skills';
import Portfolio from './components/portfolio/portfolio';

function App() {
  return (
    <div className='App'>
      <NavBar />
      <Home />
      <Skills />
      <About />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
