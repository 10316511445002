import styles from './portfolio.module.css';

import insureLandingPage from '../../assets/insureLandingPage.png';
import tipCalculator from '../../assets/tipCalculator.png';
import fullStackContactForm from '../../assets/fullStackContactForm.png';

const Portfolio = () => {
  return (
    <div id='portfolio' className={styles.portfolioContainer}>
      <h2>My Portfolio</h2>
      <div className={styles.projects}>
        <div className={styles.project}>
          <img
            className={styles.projectImg}
            src={insureLandingPage}
            alt='Landing page for a fake insurance company'
          />
          <div className={styles.projectInfo}>
            <div className={styles.projectTitle}>Insure Lading Page</div>
            <div className={styles.projectDescription}>
              A responsive website built with HTML, CSS, and JavaScript,
              featuring adaptive layouts and interactive elements.
            </div>
            <div className={styles.projectLink}>
              <a
                href='https://github.com/cjislegit/insureLandingPage'
                target='_blank'
                rel='noreferrer'
              >
                View on GitHub
              </a>
            </div>
          </div>
        </div>
        <div className={styles.project}>
          <img
            className={styles.projectImg}
            src={tipCalculator}
            alt='A calculator that calculates the tip'
          />
          <div className={styles.projectInfo}>
            <div className={styles.projectTitle}>Tip Calculator</div>
            <div className={styles.projectDescription}>
              Responsive Tip Calculator App using React, HTML, CSS Modules,
              deployed on Vercel.
            </div>
            <div className={styles.projectLink}>
              <a
                href='https://github.com/cjislegit/tip_calculator'
                target='_blank'
                rel='noreferrer'
              >
                View on GitHub
              </a>
            </div>
          </div>
        </div>
        <div className={styles.project}>
          <img
            className={styles.projectImg}
            src={fullStackContactForm}
            alt='A responsive contact form'
          />
          <div className={styles.projectInfo}>
            <div className={styles.projectTitle}>Full Stack Contact Form</div>
            <div className={styles.projectDescription}>
              Web Contact Form with HTML, PHP, MySQL, using PDO; features data
              display and editing, hosted on Heroku.
            </div>
            <div className={styles.projectLink}>
              <a
                href='https://github.com/cjislegit/simple_contact_form'
                target='_blank'
                rel='noreferrer'
              >
                View on GitHub
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
