import styles from './about.module.css';

const About = () => {
  return (
    <div id='about' className={styles.aboutContainer}>
      <h2>About</h2>
      <div>
        As a dedicated Web Developer, I specialize in creating user-friendly,
        responsive websites tailored to meet diverse needs. My technical toolkit
        includes advanced skills in JavaScript, React, Redux, HTML, CSS, PHP,
        and MySQL, complemented by proficiency in WordPress and API integration.
        I have a strong track record in front-end development, particularly in
        crafting dynamic web applications and refining UI features. My approach
        emphasizes meticulous debugging, efficient problem-solving, and timely
        project delivery. Recognized for consistently exceeding expectations, I
        am committed to leveraging my expertise to develop robust, high-quality
        web solutions.
      </div>
    </div>
  );
};

export default About;
